import axios from "axios";
import { useState } from "react"
import { toast } from "react-toastify";
import SMSConfirmationModal from "./SMSConfirmationModal";

/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
export default function SendSMSForm() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [messageBody, setMessageBody] = useState("");
  const [isModalOpen, toggleModal] = useState(false);

  const handleSendSMSToEmployee = async () => {
    if(phoneNumber.length === 0 || messageBody.length === 0){
      return toast.error("Please fill in all fields");
    }
    const id = toast.loading("Please wait...")
    try {
      const { data } = await axios.post(`/api/sendSMS`, {
          phone: phoneNumber,
          body: messageBody
        })
        toast.update(id, {render: "SMS sent", type: "success", isLoading: false, autoClose: 5000, pauseOnHover: true, closeButton: true});
        return data;
    } catch (error) {
      toast.update(id, {render: error.response.data.msg, type: "error", isLoading: false, autoClose: 5000, pauseOnHover: true, closeButton: true });
      // toast.error(error.response.data.msg)
      console.error(error);
    }
  }

  return (
    <>
      <SMSConfirmationModal open={isModalOpen} setOpen={toggleModal} messageBody={messageBody} />
      <div className="max-w-7xl mx-auto sm:px-6 px-4 lg:px-8 mt-8">
        <form className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-8 divide-y divide-gray-200">
            <div>
              <div>
                <h3 className="text-xl leading-6 font-medium text-gray-900">Product's name</h3>
                <p className="mt-1 text-md text-gray-500">
                  React App to send out SMS to employees
                </p>
              </div>

              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label htmlFor="phone-no" className="block text-md font-medium text-gray-700">
                    Phone no
                  </label>
                  <div className="mt-1">
                    <input
                      type="number"
                      name="phone-no"
                      id="phone-no"
                      placeholder="Enter Employee's Phone Number"
                      value={phoneNumber}
                      onChange={e => setPhoneNumber(e.target.value)}
                      autoComplete="given-name"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <label htmlFor="description" className="block text-md font-medium text-gray-700">
                    Message body
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="description"
                      name="description"
                      rows={5}
                      placeholder="Message Body"
                      value={messageBody}
                      onChange={e => setMessageBody(e.target.value)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                    />
                  </div>
                  <p className="mt-2 text-sm text-gray-500">Please type out your message</p>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-8 flex gap-x-6">
            <button
              onClick={ async () => {
                await handleSendSMSToEmployee();
              }}
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent text-md font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Send SMS
            </button>
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-md font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => toggleModal(true)}
            >
              Send SMS to all employees
            </button>
          </div>
        </form>
      </div>
    </>
  )
}
